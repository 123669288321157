import React, { FormEvent, useState } from "react";
import { Button, Checkbox, Select, Textarea, TextInput } from "@mantine/core";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import Logo from "images/logo.png";
import { PUBLIC_PATHS } from "routes";
import { useNavigate } from "react-router-dom";
import { UseFormReturnType } from "@mantine/form";

type TSignupForm = {
  form: UseFormReturnType<any>;
  data: Record<string, string>;
  postcode: string;
  setPostcode: (args: string) => void;
  isLoading: boolean;
  loading: boolean;
  handleSubmit: (values: unknown, event: FormEvent<HTMLFormElement>) => void;
};

function SignupForm({
  form,
  data,
  postcode,
  setPostcode,
  isLoading,
  loading,
  handleSubmit,
}: TSignupForm): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const navigate = useNavigate();

  const formatAddessOption = () => {
    const lists = Array.isArray(data?.delivery_points)
      ? data.delivery_points
      : [];
    return lists.map(({ line_1, line_2 }) => `${line_1} ${line_2}`);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <img src={Logo} alt="" />
      <p className="section-title">Create Account</p>
      <div className="mb-6">
        <TextInput
          size="lg"
          type="text"
          label="Name"
          placeholder="First name and Last name"
          {...form.getInputProps("agencyName")}
        />
      </div>
      <div className="mb-6">
        <TextInput
          size="lg"
          type="email"
          label="Email"
          placeholder="name@example.com"
          {...form.getInputProps("agencyEmail")}
        />
      </div>
      <div className="mb-6">
        <TextInput
          size="lg"
          type="number"
          label="Phone Number"
          placeholder="Company / personal phone number"
          {...form.getInputProps("agencyPhone")}
        />
      </div>
      <div className="mb-6">
        <TextInput
          size="lg"
          name="agencyPostCode"
          type="text"
          label="Post Code"
          placeholder="Post Code"
          value={postcode}
          onChange={(e) => {
            setPostcode(e.target.value);
            form.setFieldValue("agencyPostCode", e.target.value);
            form.setFieldValue("agencyAddress", "");
          }}
        />
      </div>
      <div className="w-full mb-6">
        <label className="label">Address</label>
        <Select
          nothingFound={
            isLoading && postcode.length > 0 ? "Loading..." : "No options"
          }
          placeholder="Select address"
          data={formatAddessOption()}
          {...form.getInputProps("agencyAddress")}
        />
      </div>
      <div className="mb-6">
        <TextInput
          size="lg"
          type="text"
          label="Role"
          placeholder="e.g Founder/CEO/Manager"
          {...form.getInputProps("agencyRoleName")}
        />
      </div>
      <div className="mb-6">
        <TextInput
          size="lg"
          type="text"
          label="Company Name"
          placeholder="e.g Texas shift company"
          {...form.getInputProps("agencyStaffName")}
        />
      </div>
      <div className="mb-6">
        <Textarea
          placeholder="Brief description of the company"
          label="About the Company"
          rows={4}
          cols={50}
          {...form.getInputProps("agencyAbout")}
        />
      </div>

      <div className="mb-6">
        <TextInput
          size="lg"
          rightSection={
            show ? (
              <BsEyeSlashFill
                color="#000"
                className="mr-5 cursor-pointer"
                onClick={() => setShow(!show)}
              />
            ) : (
              <IoEyeSharp
                color="#000"
                className="mr-5 cursor-pointer"
                onClick={() => setShow(!show)}
              />
            )
          }
          type={show ? "text" : "password"}
          label="Password"
          placeholder="Enter at least six characters"
          {...form.getInputProps("agencySecret")}
        />
      </div>
      <div className="mb-6">
        <TextInput
          size="lg"
          rightSection={
            showConfirm ? (
              <BsEyeSlashFill
                color="#000"
                className="mr-5 cursor-pointer"
                onClick={() => setShowConfirm(!showConfirm)}
              />
            ) : (
              <IoEyeSharp
                color="#000"
                className="mr-5 cursor-pointer"
                onClick={() => setShowConfirm(!showConfirm)}
              />
            )
          }
          type={showConfirm ? "text" : "password"}
          label="Confirm Password"
          placeholder=""
          {...form.getInputProps("confirmPassword")}
        />
      </div>
      <div className="flex items-start">
        <Checkbox />
        <p className="ml-2 -mt-2">
          By proceeding I agree have read ZShifts{" "}
          <span className="text-primary font-semibold cursor-pointer">
            <a
              href="https://zshift.io/term-of-use.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </span>
          ,{" "}
          <span className="text-primary font-semibold cursor-pointer">
            <a
              href="https://zshift.io/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </span>{" "}
          and{" "}
          <span className="text-primary font-semibold cursor-pointer">
            <a
              href="https://zshift.io/term-of-sales.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Sale
            </a>
          </span>
          .
        </p>
      </div>
      <div className="w-full">
        <Button type="submit" disabled={loading} loading={loading}>
          Sign Up
        </Button>
      </div>
      <p className="text-base">
        Already have an account?{" "}
        <span
          className="text-primary font-semibold cursor-pointer"
          onClick={() => navigate(PUBLIC_PATHS.LOGIN)}
        >
          Signin
        </span>
      </p>
    </form>
  );
}

export default SignupForm;
